import React, { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import "react-overlay-loader/styles.css";

import MainLayout from "../layouts/MainLayout";

import PageHeader from "../components/common/page-header";
import Breadcrumb from "../components/common/breadcrumb";
import Seo from "../components/common/SEO";
import AuthWrapper from "../AuthWrapper";

const REQUEST_BASE_URL = `${process.env.GATSBY_API_BASE_URL}/api/v1/users/marketplaceuser`;
const REQUEST_HEADERS = {
  Accept: "application/json",
  "Content-Type": "application/json",
  Authorization: `Bearer ${process.env.GATSBY_API_ACCESS_TOKEN}`,
};

const SignUp = (props) => {
  const [step, setStep] = useState(1);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  const password = useRef({});
  password.current = watch("password", "");

  const onSubmit = async (data) => {
    setProcessing(true);
    const url = `${REQUEST_BASE_URL}`;
    const method = "post";
    const headers = REQUEST_HEADERS;

    const response = await fetch(url, {
      method,
      headers,
      body: JSON.stringify({
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        password: data.password,
      }),
    });
    if (response.ok) {
      setStep(step + 1);
      setProcessing(false);
      reset({
        email: "",
        firstName: "",
        lastName: "",
        password: "",
      });
      setError([])
    } else {
      const err = await response.json();
      // console.log(err);
      setProcessing(false);
      setError(err.errors.map((error) => error.msg));
      return;
    }
  };


  return (
    <LoadingOverlay>
      <Loader text="Processing ..." loading={processing} />

      <MainLayout>
        <AuthWrapper isSignUp={true}>
          <Seo title="Create Account" />
          <div className="main">
            <h1 className="d-none">Sign up</h1>
            <PageHeader title="Create Account" />
            <Breadcrumb />

            <div className="page-content">
              <div className="container">
                <hr className="mb-4" />
                <div className="login-page  pt-1 pb-1 pt-md-1 pb-md-1 pt-lg-1 pb-lg-1">
                  <div className="container">
                    <div className="form-box">
                      <div className="form-tab">
                        <div className="tab-content">
                          <div>
                            {" "}
                            <h5>Create your account</h5>
                            {error && (
                              <div className="row">
                                <div className="col">
                                  <div
                                    className="alert alert-danger w-100"
                                    role="alert"
                                  >
                                    {typeof error === "string" ? (
                                      error
                                    ) : (
                                      <ul>
                                        {error.map((err, i) => (
                                          <li
                                            key={i}
                                            style={{ display: "list-item" }}
                                          >
                                            {err}
                                          </li>
                                        ))}
                                      </ul>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>{" "}
                          {step === 1 ? (
                            <>
                              <form action="#">
                                <div className="form-group">
                                  <label htmlFor="singin-email-2">
                                    Email address *
                                  </label>
                                  <input
                                    id="email"
                                    className="form-control"
                                    name="email"
                                    {...register("email", {
                                      required: {
                                        value: true,
                                        message: "Email required",
                                      },
                                      pattern: {
                                        value:
                                          /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                                        message: "Please provide a valid email",
                                      },
                                    })}
                                  />
                                  {errors.email && (
                                    <p className="text-danger">
                                      {errors.email?.message}
                                    </p>
                                  )}
                                </div>
                                <div className="form-group">
                                  <label htmlFor="singup-firstName">
                                    First Name *
                                  </label>
                                  <input
                                    className="form-control"
                                    id="singup-firstName"
                                    name="firstName"
                                    {...register("firstName", {
                                      required: {
                                        value: true,
                                        message: "First name required",
                                      },
                                      min: {
                                        value: 2,
                                        message:
                                          "First name should ne be less than 2 charachters",
                                      },
                                      max: {
                                        value: 30,
                                        message:
                                          "First name should not be longer than 30 charachter",
                                      },
                                    })}
                                  />

                                  {errors.firstName && (
                                    <p className="text-danger">
                                      {errors.firstName?.message}
                                    </p>
                                  )}
                                </div>
                                <div className="form-group">
                                  <label htmlFor="singup-lastName">
                                    Last Name *
                                  </label>
                                  <input
                                    className="form-control"
                                    id="singup-lastName"
                                    name="lastName"
                                    {...register("lastName", {
                                      required: {
                                        value: true,
                                        message: "Last name required",
                                      },
                                      min: {
                                        value: 2,
                                        message:
                                          "Last name should ne be less than 2 charachters",
                                      },
                                      max: {
                                        value: 30,
                                        message:
                                          "Last name should not be longer than 30 charachter",
                                      },
                                    })}
                                  />
                                  {errors.lastName && (
                                    <p className="text-danger">
                                      {errors.lastName?.message}
                                    </p>
                                  )}
                                </div>
                                <div className="form-group">
                                  <label htmlFor="singin-password-2">
                                    Password *
                                  </label>
                                  <input
                                    className="form-control"
                                    id="singin-password-2"
                                    name="password"
                                    type="password"
                                    ref={password}
                                    {...register("password", {
                                      required: {
                                        value: true,
                                        message: "You must specify a password",
                                      },
                                      minLength: {
                                        value: 8,
                                        message:
                                          "Password must have at least 8 characters",
                                      },
                                    })}
                                  />

                                  {errors.password && (
                                    <p className="text-danger">
                                      {errors.password?.message}
                                    </p>
                                  )}
                                </div>{" "}
                                <div className="form-group">
                                  <label htmlFor="singin-password-2">
                                    Confirm Password *
                                  </label>
                                  <input
                                    type="password"
                                    className="form-control"
                                    id="singin-password-2"
                                    name="confirmPassword"
                                    ref={password}
                                    {...register("Confirmpassword", {
                                      required: {
                                        value: true,
                                        message: "You should confirm password",
                                      },
                                      validate: (value) =>
                                        value === password.current ||
                                        "The passwords do not match",
                                    })}
                                  />

                                  {errors.Confirmpassword && (
                                    <p className="text-danger">
                                      {errors.Confirmpassword?.message}
                                    </p>
                                  )}
                                </div>
                              </form>

                              <div className="form-footer">
                                <button
                                  onClick={handleSubmit(onSubmit)}
                                  type="submit"
                                  className="btn btn-outline-primary-2"
                                >
                                  <span>Submit</span>
                                  <i className="icon-long-arrow-right"></i>
                                </button>
                              </div>
                            </>
                          ) : step === 2 ? (
                            <>{"Success! Your  account has been created."}</>
                          ) : (
                            "Failed"
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AuthWrapper>
      </MainLayout>
    </LoadingOverlay>
  );
};
export default SignUp;
